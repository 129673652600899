import axios from 'axios';

export const login = async (email, password) => {
  console.log(process.env.REACT_APP_BAKCEND_URL);
  const result = await axios.post(process.env.REACT_APP_BAKCEND_URL + '/users/v1/auth/login', {
    //
    identity: email,
    password
  });
  return result.data.data;
};

export const register = async (userData) => {
  const result = await axios.post(process.env.REACT_APP_BAKCEND_URL + '/users/v1/auth/register', {
    ...userData
  });

  return result.data.data;
};

export const verifyToken = async (token) => {
  const result = await axios.get(process.env.REACT_APP_BAKCEND_URL + '/users/v1/auth/user', {
    headers: {
      'x-auth-token': token
    }
  });

  return result.data.data.user;
};
