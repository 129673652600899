import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  DarkMode
} from '@chakra-ui/react';

// Assets
import signInImage from 'assets/img/signInImage.png';

// Custom Components
import AuthFooter from 'components/Footer/AuthFooter';
import GradientBorder from 'components/GradientBorder/GradientBorder';

import { useAuth } from '../../authContext';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

import { FaDiscord } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';

function SignIn() {
  const titleColor = 'white';
  const textColor = 'gray.400';

  let history = useHistory();

  const { contextLogin } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const [loginErrorMessage, setLoginErrorMessage] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberChange = (event) => {
    setRemember(event.target.checked);
  };

  const signInSubmit = async () => {
    if (!email || !password) {
      setLoginErrorMessage('Au moins un champ est vide !');
      return;
    }

    try {
      await contextLogin(email, password, remember);
      history.push('/dashboard/home');
    } catch (error) {
      setLoginErrorMessage("Erreur lors de la tentative d'enregistrement");
    }
  };

  const goToDiscord = () => {
    console.log('go to discord');
    window.open('https://discord.gg/zPmJsV5UwT', '_blank', 'noreferrer');
  };

  return (
    <Flex position="relative">
      <Flex
        minH="100vh"
        h={{ base: '120vh', lg: 'fit-content' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        pt={{ sm: '100px', md: '0px' }}
        flexDirection="column"
        me={{ base: 'auto', lg: '50px', xl: 'auto' }}>
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          mx={{ base: 'auto', lg: 'unset' }}
          ms={{ base: 'auto', lg: 'auto' }}
          w={{ base: '100%', md: '50%', lg: '450px' }}
          px="50px">
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            mt={{ base: '50px', md: '150px', lg: '160px', xl: '245px' }}
            mb={{ base: '60px', lg: '95px' }}>
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Vous souhaitez nous contacter ?
            </Heading>
            <Text mb="36px" ms="4px" color={textColor} fontWeight="bold" fontSize="14px">
              Plusieurs choix s'offrent a vous.
            </Text>
            <Heading fontSize={'22'} color={titleColor}>
              Rejoignez notre serveur Discord
            </Heading>
            <Button
              m={5}
              leftIcon={<FaDiscord size={35} />}
              w={'40%'}
              bgColor="#5865F2"
              color={'white'}
              colorScheme="purple"
              onClick={goToDiscord}>
              Discord
            </Button>
            <Heading fontSize={'22'} color={titleColor}>
              Envoyez nous un e-mail
            </Heading>
            <a href="mailto:salah.ouali.pro@gmail.com">
              <Button
                m={5}
                leftIcon={<IoMdMail size={35} />}
                w={'40%'}
                bgColor="green.400"
                color={'white'}
                colorScheme="purple">
                E-Mail
              </Button>
            </a>
          </Flex>
        </Flex>
        <Box
          w={{ base: '335px', md: '450px' }}
          mx={{ base: 'auto', lg: 'unset' }}
          ms={{ base: 'auto', lg: 'auto' }}
          mb="80px">
          <AuthFooter />
        </Box>
        <Box
          display={{ base: 'none', lg: 'block' }}
          overflowX="hidden"
          h="100%"
          maxW={{ md: '50vw', lg: '50vw' }}
          minH="100vh"
          w="960px"
          position="absolute"
          left="0px">
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <Text
              textAlign="center"
              color="white"
              letterSpacing="8px"
              fontSize="20px"
              fontWeight="500">
              NEURALTREND
            </Text>
            <Text
              textAlign="center"
              color="transparent"
              letterSpacing="8px"
              fontSize="36px"
              fontWeight="bold"
              bgClip="text !important"
              bg="linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)">
              VOTRE STYLE, VOS DESIGN
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
