export const supportList = {
  data: [
    {
      item_code: 'RNC1',
      name: 'T-shirt Basique pour Enfants',
      colours: [
        {
          name: 'Mauve',
          url: 'https://images.teemill.com/cbhvkuohnzkqugkwflba0stxut0bpozpp9bc41yt9qvni5dr.png'
        },
        {
          name: 'Pink',
          url: 'https://images.teemill.com/5ed79e523a5950.33632745.png'
        },
        {
          name: 'Mustard',
          url: 'https://images.teemill.com/aalpiEQJkwuTYWtRTQ5KKobUn0LahcBSdzQ5ergYc6G08xFM.png'
        },
        {
          name: 'Denim Blue',
          url: 'https://images.teemill.com/5ac7865c4b1b92.69881380.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/5ac784b4ee9c11.75415064.png'
        },
        {
          name: 'White',
          url: 'https://images.teemill.com/5ac78910f1d546.28128958.png'
        },
        {
          name: 'Bright Blue',
          url: 'https://images.teemill.com/5ac785427cb151.12626171.png'
        }
      ],
      design_placement: {
        x: 0.353010164190774,
        y: 0.2986627043090639,
        w: 0.29124315871774825,
        h: 0.36924219910846956
      }
    },
    {
      item_code: 'RNA1',
      name: 'T-shirt Basique pour Hommes',
      colours: [
        {
          name: 'Athletic Grey',
          url: 'https://images.teemill.com/5c38758a730b94.71475303.png'
        },
        {
          name: 'Tie Dye',
          url: 'https://images.teemill.com/5aaf94ab0fe488.40476676.png'
        },
        {
          name: 'Navy Blue',
          url: 'https://images.teemill.com/5c3875486e31c4.06312878.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/5c3873170cef97.99121192.png'
        },
        {
          name: 'White',
          url: 'https://images.teemill.com/5d396bc25c1826.66462174.png'
        },
        {
          name: 'Mustard',
          url: 'https://images.teemill.com/5eda07a53999a0.39149089.png'
        },
        {
          name: 'Red',
          url: 'https://images.teemill.com/5c387646e9adc4.67004685.png'
        },
        {
          name: 'Dark Grey',
          url: 'https://images.teemill.com/5c3873cc9cca98.24350985.png'
        },
        {
          name: 'Bright Blue',
          url: 'https://images.teemill.com/5c38734c3dd2d6.28532122.png'
        }
      ],
      design_placement: {
        x: 0.318608287724785,
        y: 0.23588410104011887,
        w: 0.35887412040656763,
        h: 0.4550520059435364
      }
    },
    {
      item_code: 'RNA4',
      name: 'Pull Col Rond pour Hommes',
      colours: [
        {
          name: 'Sand',
          url: 'https://images.teemill.com/8wmr0avqznf4xcy1ymrhbwzoj8pvkgkfthbjmef9jbtax1qv.png'
        },
        {
          name: 'Brown',
          url: 'https://images.teemill.com/DMTSkXquxNUshZNA6KkZjMyiVNoTbQ3xA4KvCaAEzHJBWcy7.png'
        },
        {
          name: 'Khaki',
          url: 'https://images.teemill.com/4J3nqw9mLcYJL4sxUoDdSXf6eG27JnoYXSh6rolA3dF8yZEJ.png'
        },
        {
          name: 'White',
          url: 'https://images.teemill.com/5ef30f167921e9.74601155.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/5xuduzps8ey1mopc5k4hfrskyragksxxkq3eznbuhtcuohtv.png'
        },
        {
          name: 'Light Heather',
          url: 'https://images.teemill.com/byfjww1otrhorbemulxvfpilrkt0hkupxexcuyhyempgujcb.png'
        },
        {
          name: 'Navy Blue',
          url: 'https://images.teemill.com/vld2t6dux4jojfcg4t32esjtvhhykh1ttbethzmhjr91qtok.png'
        }
      ],
      design_placement: {
        x: 0.32212666145426117,
        y: 0.24591381872213966,
        w: 0.35887412040656763,
        h: 0.4550520059435364
      }
    },
    {
      item_code: 'RNA7',
      name: 'Hoodie pour Hommes',
      colours: [
        {
          name: 'Black',
          url: 'https://images.teemill.com/yief9acnhchctjoinnynfqzj7o5mf1bo3qremvdfok5y29hc.png'
        },
        {
          name: 'Stone Blue',
          url: 'https://images.teemill.com/idn9tfcx3gmr7fhrwm06vau3e131nuuyo76d2c7ommobna7f.png'
        },
        {
          name: 'Rust',
          url: 'https://images.teemill.com/7w3nm5w8jpfz9vlyjmriszagblzta0yoapdhfopyj8psbjpj.png'
        },
        {
          name: 'White',
          url: 'https://images.teemill.com/5jizfl8je0lwxdym4zojshhaienotmml1avgb7wd2adxponn.png'
        },
        {
          name: 'Navy',
          url: 'https://images.teemill.com/mz7tzzb4y8jgraddcevpagxlhhno2yqntre62gdnlfxaocss.png'
        },
        {
          name: 'Light Heather',
          url: 'https://images.teemill.com/6qvg7ke6hx7fswc6pyohjpovabqqil7sih3rnklg56cwu4j0.png'
        }
      ],
      design_placement: {
        x: 0.33502736512900705,
        y: 0.2700594353640416,
        w: 0.3256450351837373,
        h: 0.413075780089153
      }
    },
    {
      item_code: 'RNB1',
      name: 'T-shirt à Col Échancré pour Femmes',
      colours: [
        {
          name: 'Baby Blue',
          url: 'https://images.teemill.com/5c386daddba748.52153902.png'
        }
      ],
      design_placement: {
        x: 0.33346364347146207,
        y: 0.31835066864784545,
        w: 0.33424550430023453,
        h: 0.4234769687964339
      }
    },
    {
      item_code: 'RNB3',
      name: 'Débardeur pour Femmes',
      colours: [
        {
          name: 'Black',
          url: 'https://images.teemill.com/5ac776c1ead344.28109990.png'
        },
        {
          name: 'White',
          url: 'https://images.teemill.com/5ac777485ec817.47880517.png'
        }
      ],
      design_placement: {
        x: 0.33346364347146207,
        y: 0.3588410104011887,
        w: 0.33424550430023453,
        h: 0.4234769687964339
      }
    },
    {
      item_code: 'RNT1',
      name: 'Sac Fourre-tout Coloré',
      colours: [
        {
          name: 'Natural',
          url: 'https://images.teemill.com/5c139861cc3032.80534475.png'
        },
        {
          name: 'White',
          url: 'https://images.teemill.com/5c1398281959a8.07182935.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/5c139782d66f97.40197587.png'
        }
      ],
      design_placement: {
        x: 0.34323690383111805,
        y: 0.5059435364041605,
        w: 0.31430805316653637,
        h: 0.3985884101040119
      }
    },
    {
      item_code: 'RNB13',
      name: 'Hoodie pour Femmes',
      colours: [
        {
          name: 'Navy Blue',
          url: 'https://images.teemill.com/dsvmpc9bbupietvfvuvedtq1wy5gn2sr9f8yhqks2hhdtcov.png'
        },
        {
          name: 'Stone Blue',
          url: 'https://images.teemill.com/fzheetuxpo5jehjrmbiatenkddec8t6oixidjphpe1kv2tw9.png'
        },
        {
          name: 'Mauve',
          url: 'https://images.teemill.com/1qlqfjgsagojhliktd22a7scf6oi3mnbdxwz33bhth2xmup3.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/gxcfgqsllhhfca9uk5dtxmqn0t183tflmactjgydeavel7jz.png'
        },
        {
          name: 'Light Heather',
          url: 'https://images.teemill.com/vphgrbkhpatf7d09xmfmzaf3zgmcfdohmczoythxrvrwqlcd.png'
        }
      ],
      design_placement: {
        x: 0.33541829554339325,
        y: 0.2864041604754829,
        w: 0.3295543393275997,
        h: 0.4554234769687964
      }
    },
    {
      item_code: 'RNB14',
      name: 'T-shirt à Col Rond pour Femmes',
      colours: [
        {
          name: 'White',
          url: 'https://images.teemill.com/5ac77f4693b545.02106270.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/5ac77d04d8bbb0.62144649.png'
        },
        {
          name: 'Navy Blue',
          url: 'https://images.teemill.com/5b5ee0295ea7e9.91819540.png'
        },
        {
          name: 'Pastel Green',
          url: 'https://images.teemill.com/jzv2uetzzzpo4cvla3rnaue48fuhpeyjmb0aupzwi4sidrnr.png'
        },
        {
          name: 'Sky Blue',
          url: 'https://images.teemill.com/uxdgzcq2t4vtfuxotcjimi38qja2ppkaewaqliguitdms1h6.png'
        },
        {
          name: 'Stone Blue',
          url: 'https://images.teemill.com/xtvjddfpbj3vfue7m4rv7q4hq5gqmy0wmmowz0mg0zn7weei.png'
        },
        {
          name: 'Mauve',
          url: 'https://images.teemill.com/cjaw4mnlbabo8ndgkyihfzj6daiawtmgrlnjbpbybx4mqlq7.png'
        },
        {
          name: 'Cherry',
          url: 'https://images.teemill.com/5ed77e88f09c80.03854160.png'
        },
        {
          name: 'Mustard',
          url: 'https://images.teemill.com/5eda081a6570e7.35749881.png'
        },
        {
          name: 'Khaki',
          url: 'https://images.teemill.com/5ce2d2b35a6952.11338429.png'
        },
        {
          name: 'Pink',
          url: 'https://images.teemill.com/5dbab7f6c6ff67.10845097.png'
        },
        {
          name: 'Athletic Grey',
          url: 'https://images.teemill.com/5ac77c6eb43f74.65034087.png'
        }
      ],
      design_placement: {
        x: 0.3326817826426896,
        y: 0.24702823179791977,
        w: 0.33424550430023453,
        h: 0.4234769687964339
      }
    },
    {
      item_code: 'RNB18',
      name: 'Pull à Col Rond pour Femmes',
      colours: [
        {
          name: 'Stone Blue',
          url: 'https://images.teemill.com/w0qnzqipqejdskbixcnm07qri0r3vgkjolgkxngasxcr9uqr.png'
        },
        {
          name: 'Mauve',
          url: 'https://images.teemill.com/13ja7gevpmo2z29heg5s68ifpvvtyao7ghv4mndbdmhjmggy.png'
        },
        {
          name: 'Cherry',
          url: 'https://images.teemill.com/u1la3go5ypmrdqgxzdojtcirjjhvqgflcsuytsgualgsg3uf.png'
        },
        {
          name: 'Navy Blue',
          url: 'https://images.teemill.com/oelhnlquwlz4ruirqtzwyv93xew9okpmjtfkam1ooesdaqod.png'
        },
        {
          name: 'White',
          url: 'https://images.teemill.com/5ef30ffc6fa9e7.03047979.png'
        },
        {
          name: 'Light Heather',
          url: 'https://images.teemill.com/5bb22971818196.53280831.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/5bb229701b59a8.60188354.png'
        }
      ],
      design_placement: {
        x: 0.3397185301016419,
        y: 0.262630014858841,
        w: 0.3174354964816263,
        h: 0.4026745913818722
      }
    },
    {
      item_code: 'RNB22',
      name: 'T-shirt à Manches Longues pour Femmes',
      colours: [
        {
          name: 'Stone Blue',
          url: 'https://images.teemill.com/s4wa6vkgkdys3oi8em6mj87tmzs0kjqpgzmsqed1zqmisyxx.png'
        },
        {
          name: 'Rust',
          url: 'https://images.teemill.com/czljvbzwfd1ko1cuugyo8b2mvnwv999mf0wtw6vtgbbf1pvq.png'
        },
        {
          name: 'Navy Blue',
          url: 'https://images.teemill.com/riex1nyrrcxfw1pyrxnaikq8ccjhsdshts6iuf8re1ayeco2.png'
        },
        {
          name: 'White',
          url: 'https://images.teemill.com/5d3f05757ee900.55792792.png'
        },
        {
          name: 'Athletic Grey',
          url: 'https://images.teemill.com/5d3f054fd961f9.59864941.png'
        },
        {
          name: 'Khaki',
          url: 'https://images.teemill.com/5d3f052d141ac6.19592438.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/5d3815ed8bb548.88123933.png'
        }
      ],
      design_placement: {
        x: 0.3487099296325254,
        y: 0.26560178306092125,
        w: 0.3017982799061767,
        h: 0.3800148588410104
      }
    },
    {
      item_code: 'RNA26',
      name: 'T-shirt à Manches Longues pour Hommes',
      colours: [
        {
          name: 'Athletic Grey',
          url: 'https://images.teemill.com/pyv4a2ag57n7f2lv1ojpvqypynwa42d8tmyej6c41jbyu1zg.png'
        },
        {
          name: 'Navy Blue',
          url: 'https://images.teemill.com/efyojcqkjaembdfhbhy8fgjz9d5oknsljzjvrgkfw2bjaxyj.png'
        },
        {
          name: 'White',
          url: 'https://images.teemill.com/aaLA6l6nh0wc3hBqpim5BAhH8ZQmedV14SeeRmV3trLcq4oI.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/5d3811fd29d2b0.76459450.png'
        },
        {
          name: 'Denim Blue',
          url: 'https://images.teemill.com/5d3811f6a3f5c3.32841438.png'
        }
      ],
      design_placement: {
        x: 0.326817826426896,
        y: 0.2288261515601783,
        w: 0.34597341673182175,
        h: 0.4387072808320951
      }
    },
    {
      item_code: 'RNB27',
      name: 'Tee-shirt Boxy pour Femmes',
      colours: [
        {
          name: 'White',
          url: 'https://images.teemill.com/5ee8c7b6c187f8.57127083.png'
        },
        {
          name: 'Stone Blue',
          url: 'https://images.teemill.com/qwz6fghywcnzzutzbswtjzgvjl8as7rkw85ggua6znvohyfd.png'
        },
        {
          name: 'Pastel Tie Dye',
          url: 'https://images.teemill.com/5ef30b9669ec50.52826726.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/5ee8c750e3d9d3.12550590.png'
        },
        {
          name: 'Athletic Grey',
          url: 'https://images.teemill.com/5ef30b33929e41.03765148.png'
        },
        {
          name: 'Pastel Green',
          url: 'https://images.teemill.com/q6njw7cdvyq626thx4eihqfv2vv6ffd1lpaszcvhy96pujsv.png'
        },
        {
          name: 'Sky Blue',
          url: 'https://images.teemill.com/qmc3fj1k16yndqapcpzral8iikcipapz7l7cswtg3kxzznxu.png'
        }
      ],
      design_placement: {
        x: 0.35691946833463645,
        y: 0.24071322436849926,
        w: 0.28655199374511336,
        h: 0.3636701337295691
      }
    },
    {
      item_code: 'RNB36',
      name: 'T-shirt Ample pour Femmes',
      colours: [
        {
          name: 'White',
          url: 'https://images.teemill.com/uwkdonoycoj4bkxhjtkhvuusjhdraq0gjqjazfvsiklud1qy.png'
        },
        {
          name: 'Stone Blue',
          url: 'https://images.teemill.com/1j2f2gw8ad2tgvs446evtrblyhfheoqafoum6dvv1fnhavoa.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/cd3qc7posqhjolwainivlkpyc523khpvqdjfp47dv1f2pv4r.png'
        }
      ],
      design_placement: {
        x: 0.3189992181391712,
        y: 0.25482912332838037,
        w: 0.35887412040656763,
        h: 0.4554234769687964
      }
    },
    {
      item_code: 'RNB33',
      name: 'Hoodie Ample pour Femmes',
      colours: [
        {
          name: 'White',
          url: 'https://images.teemill.com/frabjjmzngcdvv80ugth5ylmzl7yqy29tlwgvakd7nptmur3.png'
        },
        {
          name: 'Stone Blue',
          url: 'https://images.teemill.com/cim8djnoqv1bqdwqu0h2xcy4mykmarximnekwc14s8ydmgfs.png'
        },
        {
          name: 'Black',
          url: 'https://images.teemill.com/ofefgoanwjwb3b1ttil3q2o2ob4veh9mforfkcncwyaealta.png'
        },
        {
          name: 'Athletic Grey',
          url: 'https://images.teemill.com/3dfjmazjz61gepwjets0nrl0gaat8tqw6l7vyer2thek1xvl.png'
        }
      ],
      design_placement: {
        x: 0.3393275996872557,
        y: 0.29977711738484397,
        w: 0.32212666145426117,
        h: 0.40824665676077265
      }
    },
    {
      item_code: 'RNB46',
      name: 'T-shirt Uni pour Femmes',
      colours: [
        {
          name: 'White',
          url: 'https://images.teemill.com/akuewg9qfbbtqczm1nyumdqhjyzapq2jzyubck1wyxelhnkc.png'
        },
        {
          name: 'Stone Blue',
          url: 'https://images.teemill.com/bolvlb1p5sr9d0qxxds0prq3i2xwtbutlouq05ohrmkoukj9.png'
        }
      ],
      design_placement: {
        x: 0.3166536356528538,
        y: 0.27786032689450224,
        w: 0.3655199374511337,
        h: 0.46396731054977713
      }
    },
    {
      item_code: 'RNK25',
      name: 'Mug',
      colours: [
        {
          name: 'White',
          url: 'https://images.teemill.com/b6rfolu1xci0tp6gr0xosftdglbt3pfcxhvthorg4bcvtm5h.png'
        }
      ],
      design_placement: {
        x: 0.06411258795934324,
        y: 0.34992570579494797,
        w: 0.7380766223612197,
        h: 0.30349182763744426
      }
    },
    {
      item_code: 'RNKEP70',
      name: 'Sac',
      colours: [
        {
          name: 'Black',
          url: 'https://images.teemill.com/zbveklgz94hlgv9vrjakv5fess1skt46laryzjezbw7j92fu.png'
        }
      ],
      design_placement: {
        x: 0,
        y: 0,
        w: 0.003909304143862392,
        h: 0.003714710252600297
      }
    }
  ]
};

export const staticSupportImages = {
  RNC1_Mauve: require('../assets/img/support/RNC1_Mauve.webp'),
  RNC1_Pink: require('../assets/img/support/RNC1_Pink.webp'),
  RNC1_Mustard: require('../assets/img/support/RNC1_Mustard.webp'),
  'RNC1_Denim Blue': require('../assets/img/support/RNC1_Denim Blue.webp'),
  RNC1_Black: require('../assets/img/support/RNC1_Black.webp'),
  RNC1_White: require('../assets/img/support/RNC1_White.webp'),
  'RNC1_Bright Blue': require('../assets/img/support/RNC1_Bright Blue.webp'),
  'RNA1_Athletic Grey': require('../assets/img/support/RNA1_Athletic Grey.webp'),
  'RNA1_Tie Dye': require('../assets/img/support/RNA1_Tie Dye.webp'),
  'RNA1_Navy Blue': require('../assets/img/support/RNA1_Navy Blue.webp'),
  RNA1_Black: require('../assets/img/support/RNA1_Black.webp'),
  RNA1_White: require('../assets/img/support/RNA1_White.webp'),
  RNA1_Mustard: require('../assets/img/support/RNA1_Mustard.webp'),
  RNA1_Red: require('../assets/img/support/RNA1_Red.webp'),
  'RNA1_Dark Grey': require('../assets/img/support/RNA1_Dark Grey.webp'),
  'RNA1_Bright Blue': require('../assets/img/support/RNA1_Bright Blue.webp'),
  RNA4_Sand: require('../assets/img/support/RNA4_Sand.webp'),
  RNA4_Brown: require('../assets/img/support/RNA4_Brown.webp'),
  RNA4_Khaki: require('../assets/img/support/RNA4_Khaki.webp'),
  RNA4_White: require('../assets/img/support/RNA4_White.webp'),
  RNA4_Black: require('../assets/img/support/RNA4_Black.webp'),
  'RNA4_Light Heather': require('../assets/img/support/RNA4_Light Heather.webp'),
  'RNA4_Navy Blue': require('../assets/img/support/RNA4_Navy Blue.webp'),
  RNA7_Black: require('../assets/img/support/RNA7_Black.webp'),
  'RNA7_Stone Blue': require('../assets/img/support/RNA7_Stone Blue.webp'),
  RNA7_Rust: require('../assets/img/support/RNA7_Rust.webp'),
  RNA7_White: require('../assets/img/support/RNA7_White.webp'),
  RNA7_Navy: require('../assets/img/support/RNA7_Navy.webp'),
  'RNA7_Light Heather': require('../assets/img/support/RNA7_Light Heather.webp'),
  'RNB1_Baby Blue': require('../assets/img/support/RNB1_Baby Blue.webp'),
  RNB3_Black: require('../assets/img/support/RNB3_Black.webp'),
  RNB3_White: require('../assets/img/support/RNB3_White.webp'),
  RNT1_Natural: require('../assets/img/support/RNT1_Natural.webp'),
  RNT1_White: require('../assets/img/support/RNT1_White.webp'),
  RNT1_Black: require('../assets/img/support/RNT1_Black.webp'),
  'RNB13_Navy Blue': require('../assets/img/support/RNB13_Navy Blue.webp'),
  'RNB13_Stone Blue': require('../assets/img/support/RNB13_Stone Blue.webp'),
  RNB13_Mauve: require('../assets/img/support/RNB13_Mauve.webp'),
  RNB13_Black: require('../assets/img/support/RNB13_Black.webp'),
  'RNB13_Light Heather': require('../assets/img/support/RNB13_Light Heather.webp'),
  RNB14_White: require('../assets/img/support/RNB14_White.webp'),
  RNB14_Black: require('../assets/img/support/RNB14_Black.webp'),
  'RNB14_Navy Blue': require('../assets/img/support/RNB14_Navy Blue.webp'),
  'RNB14_Pastel Green': require('../assets/img/support/RNB14_Pastel Green.webp'),
  'RNB14_Sky Blue': require('../assets/img/support/RNB14_Sky Blue.webp'),
  'RNB14_Stone Blue': require('../assets/img/support/RNB14_Stone Blue.webp'),
  RNB14_Mauve: require('../assets/img/support/RNB14_Mauve.webp'),
  RNB14_Cherry: require('../assets/img/support/RNB14_Cherry.webp'),
  RNB14_Mustard: require('../assets/img/support/RNB14_Mustard.webp'),
  RNB14_Khaki: require('../assets/img/support/RNB14_Khaki.webp'),
  RNB14_Pink: require('../assets/img/support/RNB14_Pink.webp'),
  'RNB14_Athletic Grey': require('../assets/img/support/RNB14_Athletic Grey.webp'),
  'RNB18_Stone Blue': require('../assets/img/support/RNB18_Stone Blue.webp'),
  RNB18_Mauve: require('../assets/img/support/RNB18_Mauve.webp'),
  RNB18_Cherry: require('../assets/img/support/RNB18_Cherry.webp'),
  'RNB18_Navy Blue': require('../assets/img/support/RNB18_Navy Blue.webp'),
  RNB18_White: require('../assets/img/support/RNB18_White.webp'),
  'RNB18_Light Heather': require('../assets/img/support/RNB18_Light Heather.webp'),
  RNB18_Black: require('../assets/img/support/RNB18_Black.webp'),
  'RNB22_Stone Blue': require('../assets/img/support/RNB22_Stone Blue.webp'),
  RNB22_Rust: require('../assets/img/support/RNB22_Rust.webp'),
  'RNB22_Navy Blue': require('../assets/img/support/RNB22_Navy Blue.webp'),
  RNB22_White: require('../assets/img/support/RNB22_White.webp'),
  'RNB22_Athletic Grey': require('../assets/img/support/RNB22_Athletic Grey.webp'),
  RNB22_Khaki: require('../assets/img/support/RNB22_Khaki.webp'),
  RNB22_Black: require('../assets/img/support/RNB22_Black.webp'),
  'RNA26_Athletic Grey': require('../assets/img/support/RNA26_Athletic Grey.webp'),
  'RNA26_Navy Blue': require('../assets/img/support/RNA26_Navy Blue.webp'),
  RNA26_White: require('../assets/img/support/RNA26_White.webp'),
  RNA26_Black: require('../assets/img/support/RNA26_Black.webp'),
  'RNA26_Denim Blue': require('../assets/img/support/RNA26_Denim Blue.webp'),
  RNB27_White: require('../assets/img/support/RNB27_White.webp'),
  'RNB27_Stone Blue': require('../assets/img/support/RNB27_Stone Blue.webp'),
  'RNB27_Pastel Tie Dye': require('../assets/img/support/RNB27_Pastel Tie Dye.webp'),
  RNB27_Black: require('../assets/img/support/RNB27_Black.webp'),
  'RNB27_Athletic Grey': require('../assets/img/support/RNB27_Athletic Grey.webp'),
  'RNB27_Pastel Green': require('../assets/img/support/RNB27_Pastel Green.webp'),
  'RNB27_Sky Blue': require('../assets/img/support/RNB27_Sky Blue.webp'),
  RNB36_White: require('../assets/img/support/RNB36_White.webp'),
  'RNB36_Stone Blue': require('../assets/img/support/RNB36_Stone Blue.webp'),
  RNB36_Black: require('../assets/img/support/RNB36_Black.webp'),
  RNB33_White: require('../assets/img/support/RNB33_White.webp'),
  'RNB33_Stone Blue': require('../assets/img/support/RNB33_Stone Blue.webp'),
  RNB33_Black: require('../assets/img/support/RNB33_Black.webp'),
  'RNB33_Athletic Grey': require('../assets/img/support/RNB33_Athletic Grey.webp'),
  RNB46_White: require('../assets/img/support/RNB46_White.webp'),
  'RNB46_Stone Blue': require('../assets/img/support/RNB46_Stone Blue.webp'),
  RNK25_White: require('../assets/img/support/RNK25_White.webp'),
  RNKEP70_Black: require('../assets/img/support/RNKEP70_Black.webp')
};
