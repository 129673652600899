/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Link,
  Switch,
  Text,
  Icon,
  DarkMode,
  InputGroup,
  InputRightElement,
  useToast
} from '@chakra-ui/react';

// Icons
import { FaApple, FaFacebook, FaGoogle, FaCross } from 'react-icons/fa';
import { MdError } from 'react-icons/md';
// Custom Components
import AuthFooter from 'components/Footer/AuthFooter';
import GradientBorder from 'components/GradientBorder/GradientBorder';

// Assets
import signUpImage from 'assets/img/signUpImage.png';
import { IconBase } from 'react-icons';

import { useAuth } from '../../authContext';

function SignUp() {
  const titleColor = 'white';
  const textColor = 'gray.400';

  let history = useHistory();

  const toast = useToast();

  const { contextRegister } = useAuth();

  const [isPasswordShowing, setIsPasswordShowing] = useState(false);
  const [isConfirmPasswordShowing, setIsConfirmPasswordShowing] = useState(false);

  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [remember, setRemember] = useState(false);

  const [registerErrorMessage, setRegisterErrorMessage] = useState(false);
  const [isPhoneNumberFormatWrong, setIsPhoneNumberFormatWrong] = useState(false);

  const handleFirstnameChange = (event) => {
    setFirstname(event.target.value);
  };

  const handleLastnameChange = (event) => {
    setLastname(event.target.value);
  };

  const phoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleRememberChange = (event) => {
    setRemember(event.target.checked);
  };

  const checkPhoneNumberFormat = () => {
    const regexp = new RegExp('^\\+\\d{1,3}\\s?\\d{6,14}$');
    if (regexp.test(phoneNumber)) {
      setIsPhoneNumberFormatWrong(true);
      return true;
    }
  };

  const registerSubmit = async () => {
    if (!firstname || !lastname || !phoneNumber || !email || !password || !confirmPassword) {
      setRegisterErrorMessage(true);
      return;
    }
    if (checkPhoneNumberFormat()) return;

    const registerPayload = {
      firstname,
      lastname,
      email,
      password,
      password_confirmation: confirmPassword,
      phone_number: phoneNumber
    };

    try {
      const registerResult = await contextRegister(registerPayload, remember);
      history.push('/dashboard/home');
    } catch (error) {
      if (!toast.isActive('registerErrorToast')) {
        toast({
          id: 'registerErrorToast',
          title: 'Une erreur est survenu !',
          description: 'Veuillez tenter à nouveau ou contacter notre support.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-left'
        });
      }
    }
  };

  return (
    <Flex position="relative" overflow={{ lg: 'hidden' }}>
      <Flex
        flexDirection="column"
        h={{ sm: 'initial', md: 'unset' }}
        w={{ base: '90%' }}
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        pt={{ sm: '100px', md: '0px' }}
        me={{ base: 'auto', lg: '50px', xl: 'auto' }}>
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          flexDirection="column"
          mx={{ base: 'auto', lg: 'unset' }}
          ms={{ base: 'auto', lg: 'auto' }}
          mb="50px"
          w={{ base: '100%', md: '50%', lg: '42%' }}>
          <Flex
            direction="column"
            textAlign="center"
            justifyContent="center"
            align="center"
            mt={{ base: '60px', md: '140px', lg: '200px' }}
            mb="50px">
            <Text fontSize="4xl" lineHeight="39px" color="white" fontWeight="bold">
              Bienvenue
            </Text>
            <Text
              fontSize="md"
              color="white"
              fontWeight="normal"
              mt="10px"
              w={{ base: '100%', md: '90%', lg: '90%', xl: '80%' }}>
              Inscrivez-vous afin de profiter des fonctionnalités de notre plateforme.
            </Text>
          </Flex>
          <GradientBorder p="2px" me={{ base: 'none', lg: '30px', xl: 'none' }}>
            <Flex
              background="transparent"
              borderRadius="30px"
              direction="column"
              p="40px"
              minW={{ base: 'unset', md: '430px', xl: '450px' }}
              w="100%"
              mx={{ base: '0px' }}
              bg={{
                base: 'rgb(19,21,56)'
              }}>
              {/* <Text fontSize="xl" color={textColor} fontWeight="bold" textAlign="center" mb="22px">
                S'inscrire avec
              </Text>
              <HStack spacing="15px" justify="center" mb="22px">
                <GradientBorder borderRadius="15px">
                  <Flex
                    _hover={{ filter: 'brightness(120%)' }}
                    transition="all .25s ease"
                    cursor="pointer"
                    justify="center"
                    align="center"
                    bg="rgb(19,21,54)"
                    w="71px"
                    h="71px"
                    borderRadius="15px">
                    <Link href="#">
                      <Icon
                        color={titleColor}
                        as={FaFacebook}
                        w="30px"
                        h="30px"
                        _hover={{ filter: 'brightness(120%)' }}
                      />
                    </Link>
                  </Flex>
                </GradientBorder>

                <GradientBorder borderRadius="15px">
                  <Flex
                    _hover={{ filter: 'brightness(120%)' }}
                    transition="all .25s ease"
                    cursor="pointer"
                    justify="center"
                    align="center"
                    bg="rgb(19,21,54)"
                    w="71px"
                    h="71px"
                    borderRadius="15px">
                    <Link href="#">
                      <Icon
                        color={titleColor}
                        as={FaGoogle}
                        w="30px"
                        h="30px"
                        _hover={{ filter: 'brightness(120%)' }}
                      />
                    </Link>
                  </Flex>
                </GradientBorder>
              </HStack>
              <Text fontSize="lg" color="gray.400" fontWeight="bold" textAlign="center" mb="22px">
                ou
              </Text> */}
              <FormControl>
                <FormLabel color={titleColor} ms="4px" fontSize="sm" fontWeight="normal">
                  Nom
                </FormLabel>

                <GradientBorder
                  mb="24px"
                  h="50px"
                  w={{ base: '100%', lg: 'fit-content' }}
                  borderRadius="20px">
                  <Input
                    color={titleColor}
                    bg={{
                      base: 'rgb(19,21,54)'
                    }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: '100%', md: '346px' }}
                    maxW="100%"
                    h="46px"
                    type="text"
                    placeholder="Votre nom"
                    onChange={handleLastnameChange}
                    value={lastname}
                  />
                </GradientBorder>

                <FormLabel color={titleColor} ms="4px" fontSize="sm" fontWeight="normal">
                  Prénom
                </FormLabel>

                <GradientBorder
                  mb="24px"
                  h="50px"
                  w={{ base: '100%', lg: 'fit-content' }}
                  borderRadius="20px">
                  <Input
                    color={titleColor}
                    bg={{
                      base: 'rgb(19,21,54)'
                    }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: '100%', md: '346px' }}
                    maxW="100%"
                    h="46px"
                    type="text"
                    placeholder="Votre prénom"
                    onChange={handleFirstnameChange}
                    value={firstname}
                  />
                </GradientBorder>

                <FormLabel color={titleColor} ms="4px" fontSize="sm" fontWeight="normal">
                  Numéro de téléphone
                </FormLabel>

                <GradientBorder
                  mb="24px"
                  h="50px"
                  w={{ base: '100%', lg: 'fit-content' }}
                  borderRadius="20px">
                  <Input
                    color={titleColor}
                    bg={{
                      base: 'rgb(19,21,54)'
                    }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: '100%', md: '346px' }}
                    maxW="100%"
                    h="46px"
                    type="tel"
                    placeholder="+33 7 42 42 42 42"
                    onChange={phoneNumberChange}
                    value={phoneNumber}
                  />
                  {isPhoneNumberFormatWrong && (
                    <Icon color={'red'} as={MdError} w="30px" h="30px" />
                  )}
                </GradientBorder>

                <FormLabel color={titleColor} ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <GradientBorder
                  mb="24px"
                  h="50px"
                  w={{ base: '100%', lg: 'fit-content' }}
                  borderRadius="20px">
                  <Input
                    color={titleColor}
                    bg={{
                      base: 'rgb(19,21,54)'
                    }}
                    border="transparent"
                    borderRadius="20px"
                    fontSize="sm"
                    size="lg"
                    w={{ base: '100%', md: '346px' }}
                    maxW="100%"
                    h="46px"
                    type="email"
                    placeholder="Votre adresse email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                </GradientBorder>
                <FormLabel color={titleColor} ms="4px" fontSize="sm" fontWeight="normal">
                  Mot de passe
                </FormLabel>
                <GradientBorder
                  mb="24px"
                  h="50px"
                  w={{ base: '100%', lg: 'fit-content' }}
                  borderRadius="20px">
                  <InputGroup size="md">
                    <Input
                      color={titleColor}
                      bg={{
                        base: 'rgb(19,21,54)'
                      }}
                      border="transparent"
                      borderRadius="20px"
                      fontSize="sm"
                      size="lg"
                      w={{ base: '100%', md: '346px' }}
                      maxW="100%"
                      h="46px"
                      type={isPasswordShowing ? 'text' : 'password'}
                      placeholder="Votre mot de passe"
                      value={password}
                      onChange={handlePasswordChange}
                    />
                    <InputRightElement width="5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        mt={1.5}
                        onClick={() => setIsPasswordShowing(!isPasswordShowing)}>
                        {isPasswordShowing ? 'Cacher' : 'Voir'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </GradientBorder>
                <FormLabel color={titleColor} ms="4px" fontSize="sm" fontWeight="normal">
                  Confirmation du mot de passe
                </FormLabel>
                <GradientBorder
                  mb="24px"
                  h="50px"
                  w={{ base: '100%', lg: 'fit-content' }}
                  borderRadius="20px">
                  <InputGroup size="md">
                    <Input
                      color={titleColor}
                      bg={{
                        base: 'rgb(19,21,54)'
                      }}
                      border="transparent"
                      borderRadius="20px"
                      fontSize="sm"
                      size="lg"
                      w={{ base: '100%', md: '346px' }}
                      maxW="100%"
                      h="46px"
                      type={isConfirmPasswordShowing ? 'text' : 'password'}
                      placeholder="Confirmer votre mot de passe"
                      value={confirmPassword}
                      onChange={handleConfirmPasswordChange}
                    />
                    <InputRightElement width="5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        mt={1.5}
                        onClick={() => setIsConfirmPasswordShowing(!isConfirmPasswordShowing)}>
                        {isConfirmPasswordShowing ? 'Cacher' : 'Voir'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </GradientBorder>
                <FormControl display="flex" alignItems="center" mb="24px">
                  <DarkMode>
                    <Switch
                      id="remember-login"
                      colorScheme="brand"
                      me="10px"
                      value={remember}
                      onChange={handleRememberChange}
                    />
                  </DarkMode>

                  <FormLabel color={titleColor} htmlFor="remember-login" mb="0" fontWeight="normal">
                    Se souvenir de moi
                  </FormLabel>
                </FormControl>
                {registerErrorMessage && (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    maxW="100%"
                    mt="20px">
                    <Text color={'red.400'}>Formulaire incorrecte !</Text>
                  </Flex>
                )}
                <Button
                  variant="brand"
                  fontSize="10px"
                  type="submit"
                  w="100%"
                  maxW="350px"
                  h="45"
                  mb="20px"
                  mt="20px"
                  onClick={registerSubmit}>
                  S'inscrire
                </Button>
              </FormControl>
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="0px">
                <Text color={textColor} fontWeight="medium">
                  Vous avez déjà un compte ?
                  <Link
                    color={titleColor}
                    as="span"
                    ms="5px"
                    href="#"
                    onClick={() => history.push('/auth/signin')}
                    fontWeight="bold">
                    Se connecter
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </GradientBorder>
        </Flex>
        <Box
          w={{ base: '335px', md: '450px' }}
          mx={{ base: 'auto', lg: 'unset' }}
          ms={{ base: 'auto', lg: 'auto' }}
          mb="90px">
          <AuthFooter />
        </Box>
        <Box
          display={{ base: 'none', lg: 'block' }}
          overflowX="hidden"
          h="1650px"
          maxW={{ md: '50vw', lg: '48vw' }}
          w="960px"
          position="absolute"
          left="0px">
          <Box
            bgImage={signUpImage}
            w="100%"
            h="1650px"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <Text
              textAlign="center"
              color="white"
              letterSpacing="8px"
              fontSize="20px"
              fontWeight="500">
              NEURALTREND
            </Text>
            <Text
              textAlign="center"
              color="transparent"
              letterSpacing="8px"
              fontSize="36px"
              fontWeight="bold"
              bgClip="text !important"
              bg="linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)">
              VOTRE STYLE, VOS DESIGN
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignUp;
