import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './authContext';

const PublicOnlyRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={(props) =>
        !user ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard/home" /> // Redirige les utilisateurs connectés vers le tableau de bord
        )
      }
    />
  );
};

export default PublicOnlyRoute;
