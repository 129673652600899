/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Flex, Icon, Td, Text, Tr, useColorModeValue, Button, Image } from '@chakra-ui/react';
import { FaEye, FaTshirt } from 'react-icons/fa';
import React from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from '@chakra-ui/react';

function DashboardTableRow(props) {
  const { logo, name, lastItem, image, date } = props;
  const textColor = useColorModeValue('gray.700', 'white');
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Aperçu du T-Shirt</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image src={image} referrerpolicy="no-referrer" />
          </ModalBody>
          <ModalFooter>
            {/* <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fermer
            </Button> */}
            <Button maxW="135px" fontSize="14px" variant="brand" onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tr>
        <Td
          minWidth={{ sm: '250px' }}
          ps="0px"
          borderBottomColor="#56577A"
          border={lastItem ? 'none' : null}>
          <Flex align="center" py=".8rem" minWidth="100%" flexWrap="nowrap">
            <Icon as={FaTshirt} h={'24px'} w={'24px'} me="18px" />
            <Text fontSize="sm" color="#fff" fontWeight="normal" minWidth="100%">
              {name}
            </Text>
          </Flex>
        </Td>

        <Td borderBottomColor="#56577A" border={lastItem ? 'none' : null}>
          <Flex cursor="pointer">
            <FaEye
              size={20}
              onClick={onOpen}
              transition="all 0.3s ease-in-out"
              _hover={{ transform: 'scale(1.5)' }}></FaEye>
          </Flex>
        </Td>
        <Td borderBottomColor="#56577A" border={lastItem ? 'none' : null}>
          <Text fontSize="sm" color="#fff" fontWeight="bold" pb=".5rem">
            {date}
          </Text>
        </Td>
      </Tr>
    </>
  );
}

export default DashboardTableRow;
