import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  DarkMode
} from '@chakra-ui/react';

// Assets
import signInImage from 'assets/img/signInImage.png';

// Custom Components
import AuthFooter from 'components/Footer/AuthFooter';
import GradientBorder from 'components/GradientBorder/GradientBorder';

import { useAuth } from '../../authContext';
import { NavLink } from 'react-router-dom/cjs/react-router-dom.min';

function SignIn() {
  const titleColor = 'white';
  const textColor = 'gray.400';

  let history = useHistory();

  const { contextLogin } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [remember, setRemember] = useState(false);

  const [loginErrorMessage, setLoginErrorMessage] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberChange = (event) => {
    setRemember(event.target.checked);
  };

  const signInSubmit = async () => {
    if (!email || !password) {
      setLoginErrorMessage('Au moins un champ est vide !');
      return;
    }

    try {
      await contextLogin(email, password, remember);
      history.push('/dashboard/home');
    } catch (error) {
      setLoginErrorMessage("Erreur lors de la tentative d'enregistrement");
    }
  };

  return (
    <Flex position="relative">
      <Flex
        minH="100vh"
        h={{ base: '120vh', lg: 'fit-content' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        pt={{ sm: '100px', md: '0px' }}
        flexDirection="column"
        me={{ base: 'auto', lg: '50px', xl: 'auto' }}>
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          mx={{ base: 'auto', lg: 'unset' }}
          ms={{ base: 'auto', lg: 'auto' }}
          w={{ base: '100%', md: '50%', lg: '450px' }}
          px="50px">
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            mt={{ base: '50px', md: '150px', lg: '160px', xl: '245px' }}
            mb={{ base: '60px', lg: '95px' }}>
            <Heading color={titleColor} fontSize="32px" mb="10px">
              Content de vous revoir
            </Heading>
            <Text mb="36px" ms="4px" color={textColor} fontWeight="bold" fontSize="14px">
              Entrez votre email et mot de passe pour vous connecter
            </Text>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal" color="white">
                Email
              </FormLabel>
              <GradientBorder mb="24px" w={{ base: '100%', lg: 'fit-content' }} borderRadius="20px">
                <Input
                  color="white"
                  bg="rgb(19,21,54)"
                  border="transparent"
                  borderRadius="20px"
                  fontSize="sm"
                  size="lg"
                  w={{ base: '100%', md: '346px' }}
                  maxW="100%"
                  h="46px"
                  placeholder="geronimo@mail.fr"
                  value={email}
                  onChange={handleEmailChange}
                />
              </GradientBorder>
            </FormControl>
            <FormControl>
              <FormLabel ms="4px" fontSize="sm" fontWeight="normal" color="white">
                Mot de passe
              </FormLabel>
              <GradientBorder mb="24px" w={{ base: '100%', lg: 'fit-content' }} borderRadius="20px">
                <Input
                  color="white"
                  bg="rgb(19,21,54)"
                  border="transparent"
                  borderRadius="20px"
                  fontSize="sm"
                  size="lg"
                  w={{ base: '100%', md: '346px' }}
                  maxW="100%"
                  type="password"
                  placeholder="Votre mot de passe"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </GradientBorder>
            </FormControl>
            <FormControl display="flex" alignItems="center">
              <DarkMode>
                <Switch
                  id="remember-login"
                  colorScheme="brand"
                  me="10px"
                  value={remember}
                  onChange={handleRememberChange}
                />
              </DarkMode>
              <FormLabel htmlFor="remember-login" mb="0" ms="1" fontWeight="normal" color="white">
                Se souvenir de moi
              </FormLabel>
            </FormControl>
            {loginErrorMessage && (
              <Flex
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                maxW="100%"
                mt="20px">
                <Text color={'red.400'}>{loginErrorMessage}</Text>
              </Flex>
            )}
            <Button
              variant="brand"
              fontSize="10px"
              type="submit"
              w="100%"
              maxW="350px"
              h="45"
              mb="20px"
              mt="20px"
              onClick={signInSubmit}>
              Se connecter
            </Button>

            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px">
              <Text color={textColor} fontWeight="medium">
                Pas encore de compte ?
                <Link color={titleColor} as="span" ms="5px" fontWeight="bold">
                  <NavLink to="/auth/signup">Inscription</NavLink>
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          w={{ base: '335px', md: '450px' }}
          mx={{ base: 'auto', lg: 'unset' }}
          ms={{ base: 'auto', lg: 'auto' }}
          mb="80px">
          <AuthFooter />
        </Box>
        <Box
          display={{ base: 'none', lg: 'block' }}
          overflowX="hidden"
          h="100%"
          maxW={{ md: '50vw', lg: '50vw' }}
          minH="100vh"
          w="960px"
          position="absolute"
          left="0px">
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center">
            <Text
              textAlign="center"
              color="white"
              letterSpacing="8px"
              fontSize="20px"
              fontWeight="500">
              NEURALTREND
            </Text>
            <Text
              textAlign="center"
              color="transparent"
              letterSpacing="8px"
              fontSize="36px"
              fontWeight="bold"
              bgClip="text !important"
              bg="linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)">
              VOTRE STYLE, VOS DESIGN
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignIn;
