import React, { createContext, useState, useContext, useEffect } from 'react';

import Cookies from 'js-cookie';

const AuthContext = createContext(null);

import { login, verifyToken } from 'services/authService';
import { register } from 'services/authService';

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const token = Cookies.get('authToken');
    if (token) {
      verifyToken(token)
        .then((user) => {
          setUser({ token: { token }, user });
          setIsLoading(false); // Définir isLoading sur false une fois la vérification terminée
        })
        .catch(() => {
          setIsLoading(false); // Également définir sur false en cas d'erreur
        });
    } else {
      setIsLoading(false); // Pas de token, donc pas de chargement nécessaire
    }
  }, []);

  const contextLogin = async (email, password, remember) => {
    console.log('inside login : ', remember);
    try {
      const loginResult = await login(email, password);

      Cookies.set('authToken', loginResult.token.token, {
        expires: remember ? 7 : null
      });

      setUser(loginResult);
    } catch (error) {
      console.log('Auth failed : ', error);
      throw new Error('Authentication failed');
    }
  };

  const contextRegister = async (registerPayload, remember) => {
    try {
      const registerResult = await register(registerPayload);
      Cookies.set('authToken', registerResult.token.token, {
        expires: remember ? 7 : null
      });

      setUser(registerResult);
    } catch (error) {
      console.log('Register failed : ', error);
      throw new Error('Register failed');
    }
  };

  const logout = () => {
    setUser(null);
    Cookies.remove('authToken');
  };

  return (
    <AuthContext.Provider value={{ user, isLoading, contextLogin, contextRegister, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
