export const toUTCPlusOne = (dateString, option) => {
  let date = new Date(dateString);

  date.setHours(date.getHours() + 1);

  // Formatez la date
  let day = date.getDate().toString().padStart(2, '0');
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mois commence à 0
  let year = date.getFullYear();
  let hours = date.getHours().toString().padStart(2, '0');
  let minutes = date.getMinutes().toString().padStart(2, '0');
  let seconds = date.getSeconds().toString().padStart(2, '0');

  switch (option) {
    case 'date':
      return `${day}-${month}-${year}`;
    case 'time':
      return `${hours}:${minutes}:${seconds}`;
    default:
      return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`;
  }
};
