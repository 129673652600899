/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// import
import Dashboard from 'views/Dashboard/Dashboard.js';
import Tables from 'views/Dashboard/Tables.js';
import Billing from 'views/Dashboard/Billing.js';
import RTLPage from 'views/RTL/RTLPage.js';
import Profile from 'views/Dashboard/Profile.js';
import SignIn from 'views/Pages/SignIn.js';
import SignUp from 'views/Pages/SignUp.js';
import AuthContact from 'views/Pages/AuthContact.js';
import CreateTshirt from 'views/Dashboard/CreateTshirt.js';

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon
} from 'components/Icons/Icons';

var dashRoutes = [
  {
    path: '/home',
    name: 'Accueil',
    rtlName: 'لوحة القيادة',
    icon: <HomeIcon color="inherit" />,
    component: Dashboard,
    layout: '/dashboard',
    showInSidebar: true,
    requiresAuth: true
  },
  {
    path: '/create',
    name: 'Créer un vêtement',
    rtlName: 'لوحة القيادة',
    icon: <HomeIcon color="inherit" />,
    component: CreateTshirt,
    layout: '/dashboard',
    showInSidebar: true,
    requiresAuth: true
  },
  // {
  //   path: '/tables',
  //   name: 'Tables',
  //   rtlName: 'لوحة القيادة',
  //   icon: <StatsIcon color="inherit" />,
  //   component: Tables,
  //   layout: '/dashboard',
  //   requiresAuth: false,
  //   showInSidebar: false,
  //   requiresAuth: true
  // }
  // {
  //   path: '/billing',
  //   name: 'Billing',
  //   rtlName: 'لوحة القيادة',
  //   icon: <CreditIcon color="inherit" />,
  //   component: Billing,
  //   layout: '/dashboard',
  //   showInSidebar: false,
  //   requiresAuth: true
  // },
  // {
  //   path: "/rtl-support-page",
  //   name: "RTL",
  //   rtlName: "آرتيإل",
  //   icon: <SupportIcon color='inherit' />,
  //   component: RTLPage,
  //   layout: "/rtl",
  //   showInSidebar: false,
  // },
  {
    name: 'ACCOUNT PAGES',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    showInSidebar: false,
    requiresAuth: true,
    views: [
      {
        path: '/profile',
        name: 'Profile',
        rtlName: 'لوحة القيادة',
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: '/dashboard',
        showInSidebar: true,
        requiresAuth: true
      },
      {
        path: '/signin',
        name: 'Sign In',
        rtlName: 'لوحة القيادة',
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: '/auth',
        showInSidebar: true
      },
      {
        path: '/signup',
        name: 'Sign Up',
        rtlName: 'لوحة القيادة',
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: '/auth',
        showInSidebar: true
      },
      {
        path: '/contact',
        name: 'Contact',
        rtlName: 'لوحة القيادة',
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: false,
        component: AuthContact,
        layout: '/auth',
        showInSidebar: false
      }
    ]
  }
];
export default dashRoutes;
