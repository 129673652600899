import axios from 'axios';

export const generateImageFromBackEnd = async (prompt, userToken) => {
  try {
    console.log('here');
    const result = await axios.post(
      process.env.REACT_APP_BAKCEND_URL + '/generation/v1/create-tshirt',
      {
        prompt
      },
      {
        headers: {
          'x-auth-token': userToken
        }
      }
    );
    console.log(result);
    return result.data.data;
  } catch (error) {
    console.log(error);
  }
};

export const createProductFromBackEnd = async (
  prompt,
  imageUrl,
  userToken,
  colours,
  itemCode,
  itemName
) => {
  try {
    const result = await axios.post(
      process.env.REACT_APP_BAKCEND_URL + '/product/v1/create-product',
      {
        prompt,
        imageUrl,
        colours,
        itemCode,
        itemName
      },
      {
        headers: {
          'x-auth-token': userToken
        }
      }
    );
    console.log(result.data);
    return result.data.data.productUrl;
  } catch (error) {
    console.log(error);
  }
};

export const getUserProducts = async (userToken) => {
  const result = await axios.get(process.env.REACT_APP_BAKCEND_URL + '/users/v1/user/products', {
    headers: {
      'x-auth-token': userToken
    }
  });
  console.log(result);
  return result.data.data.userProducts;
};

export const getUserProductsCount = async (userToken) => {
  const result = await axios.get(
    process.env.REACT_APP_BAKCEND_URL + '/users/v1/user/products-count',
    {
      headers: {
        'x-auth-token': userToken
      }
    }
  );
  console.log(result);
  return result.data.data.userProductsCount;
};
