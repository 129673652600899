// PrivateRoute.js
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from './authContext';
import PacmanLoader from 'react-spinners/PacmanLoader';

const override = {
  position: 'absolute',
  'margin-left': 'auto',
  'margin-right': 'auto',
  top: '50%',
  '-ms-transform': 'translateY(-50%)',
  transform: 'translateY(-50%)'
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    // Si l'état de chargement est vrai, vous pouvez afficher un spinner de chargement ou simplement retourner null
    return <PacmanLoader size={100} color="white" cssOverride={override} />; // Remplacez <Spinner /> par votre composant de chargement
  }

  return (
    <Route
      {...rest}
      render={(props) => (user ? <Component {...props} /> : <Redirect to="/auth/signin" />)}
    />
  );
};

export default PrivateRoute;
