/*!

=========================================================
* Vision UI Free Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-free-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)
* Licensed under MIT (https://github.com/creativetimofficial/vision-ui-free-chakra/blob/master LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Chakra imports
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Flex,
  Icon,
  Input,
  Text,
  InputGroup,
  InputLeftAddon,
  Image,
  Badge,
  Grid,
  GridItem,
  Heading,
  Stack,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Divider,
  FormControl,
  Spacer,
  Checkbox,
  Wrap,
  WrapItem,
  UnorderedList,
  ListItem,
  useToast,
  BeatLoader,
  Center,
  Textarea
} from '@chakra-ui/react';
import { Formik, Form, Field } from 'formik';
import avatar11 from 'assets/img/avatars/avatar11.png';

import Card from 'components/Card/Card';
import { TbCircleDotted } from 'react-icons/tb';

import React, { useState, useEffect } from 'react';
import { FaTshirt } from 'react-icons/fa';

import { supportList, staticSupportImages } from '../../variables/supportList';

import 'simplebar-react/dist/simplebar.min.css';

//API
import { generateImageFromBackEnd, createProductFromBackEnd } from 'services/apiService';

//Context
import { useAuth } from '../../authContext';

const promptInputHandler = (isMobile) => {
  if (isMobile) {
    return (
      <box mb={2}>
        {' '}
        <Box bgColor={'white'} mb={2} borderRadius={4}>
          Prompt
        </Box>
        <InputGroup borderColor="white.700">
          <Textarea
            textColor={'whiteAlpha.900'}
            type="text"
            placeholder="Une banane sur une moto portant des flingues dans ses mains"
            size="lg"
          />
        </InputGroup>
      </box>
    );
  } else {
    return (
      <InputGroup borderColor="white.700">
        <InputLeftAddon children="Prompt" />
        <Input
          textColor={'whiteAlpha.900'}
          type="text"
          placeholder="Une banane sur une moto portant des flingues dans ses mains"
        />
      </InputGroup>
    );
  }
};

function Profile() {
  const { user } = useAuth();
  const toast = useToast();

  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [isImageGenerating, setIsImageGenerating] = useState(false);
  const [productUrl, setProductUrl] = useState('');
  const [lastColourUrl, setLastColourUrl] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentSupportSelection, setCurrentSupportSelection] = useState(null);

  const [isSupportChoosed, setIsSupportChoosed] = useState(false);
  const [choosedSupportColours, setChoosedSupportColours] = useState(null);
  const [choosedSupportItemCode, setChoosedSupportItemCode] = useState(null);

  const [isProducGenerating, setIsProductGenerating] = useState(false);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const generateImageSubmit = async () => {
    if (isImageGenerating) {
      if (!toast.isActive('imageAlreadyInGeneration')) {
        toast({
          id: 'imageAlreadyInGeneration',
          title: 'Action impossible',
          description: 'Une image est déjà en cours de génération.',
          status: 'warning',
          duration: 9000,
          isClosable: true,
          position: 'bottom-left'
        });
      }
      return;
    }
    if (prompt == '') {
      if (!toast.isActive('promptEmptyToast')) {
        toast({
          id: 'promptEmptyToast',
          title: 'Une erreur est survenu !',
          description: 'Le prompt est vide.',
          status: 'error',
          duration: 9000,
          isClosable: true,
          position: 'bottom-left'
        });
      }
      return;
    }
    setProductUrl(null);
    console.log(prompt);
    setIsImageGenerating(true);
    const tShirtData = await generateImageFromBackEnd(prompt, user.token.token);
    setIsImageGenerating(false);
    console.log(tShirtData.imageUrl);
    setImageUrl(tShirtData.imageUrl);
    toast({
      title: 'Bonne nouvelle !',
      description: "L'image a bien été généré.",
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'bottom-left'
    });
  };

  const setPromptFromInput = (event) => {
    setPrompt(event.target.value);
  };

  const openSupportCustomModal = (support) => {
    setCurrentSupportSelection(support);
    onOpen();
  };

  const chooseSupportSubmit = async (values) => {
    // alert(JSON.stringify(values, null, 2));
    if (values.checked.length === 0) {
      alert('Vous devez choisir une couleur');
      return;
    }

    console.log(values.checked.join(','));
    setChoosedSupportColours(values.checked.join(','));
    setIsSupportChoosed(true);
    values.checked = [];
    onClose();
  };

  const resetChoosenSupport = () => {
    setIsSupportChoosed(false);
    setChoosedSupportColours(null);
    setLastColourUrl(null);
    setChoosedSupportItemCode(null);
  };

  const createProductSubmit = async () => {
    setIsProductGenerating(true);
    const resultProductUrl = await createProductFromBackEnd(
      prompt,
      imageUrl,
      user.token.token,
      choosedSupportColours,
      choosedSupportItemCode,
      currentSupportSelection.name
    );
    setIsProductGenerating(false);
    setProductUrl(resultProductUrl);
    toast({
      title: 'Bonne nouvelle !',
      description: 'Votre produit a bien été généré.',
      status: 'success',
      duration: 9000,
      isClosable: true,
      position: 'bottom-left'
    });
  };

  return (
    <Flex direction="column" mt={{ sm: '25px', md: '0px' }}>
      {/* Custom support modal */}
      <Formik
        initialValues={{
          checked: []
        }}
        onSubmit={chooseSupportSubmit}>
        {({ values }) => (
          <Modal onClose={onClose} size={'xl'} isOpen={isOpen} isCentered>
            <ModalOverlay backdropFilter="auto" backdropBlur="4px" />
            <ModalContent>
              <Form>
                <ModalHeader>Choix de la couleur</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Grid
                    //templateRows="repeat(3, 1fr)"
                    height={'fit-content'}
                    templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }}
                    templateRows={{
                      base: 'repeat(3, 1fr)',
                      md: 'repeat(3, 1fr)',
                      lg: 'repeat(3, 1fr)',
                      '2xl': 'repeat(3, 1fr)'
                    }}
                    gap={4}>
                    <GridItem
                      rowSpan={{
                        base: 2,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        '2xl': 1
                      }}
                      colSpan={{
                        base: 2,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        '2xl': 1
                      }}>
                      {currentSupportSelection && currentSupportSelection.colours && (
                        <Image
                          referrerPolicy="no-referrer"
                          src={
                            lastColourUrl
                              ? staticSupportImages[lastColourUrl]
                              : staticSupportImages[
                                  `${currentSupportSelection.item_code}_${currentSupportSelection.colours[0].name}`
                                ]
                          }
                          transition="all .3s ease"
                          _hover={{ transform: 'scale(1.2)' }}
                        />
                      )}
                    </GridItem>
                    <GridItem
                      rowSpan={{
                        base: 1,
                        md: 1,
                        lg: 2,
                        xl: 2,
                        '2xl': 2
                      }}
                      colSpan={{
                        base: 2,
                        md: 1,
                        lg: 1,
                        xl: 1,
                        '2xl': 1
                      }}
                      minHeight={'60%'}
                      height={'fit-content'}>
                      {currentSupportSelection && currentSupportSelection.name && (
                        <Heading fontSize="xl">{currentSupportSelection.name}</Heading>
                      )}
                      <Divider></Divider>
                      <Box p={5} maxHeight={'100px'}>
                        <Heading fontSize="xl">Couleurs</Heading>
                        <Box p={5}>
                          <Stack spacing={[1, 2]} direction={['column']}>
                            {currentSupportSelection &&
                              currentSupportSelection.colours &&
                              currentSupportSelection.colours.map((colour, index) => {
                                return (
                                  <Wrap>
                                    <WrapItem
                                      onClick={() =>
                                        setLastColourUrl(
                                          `${currentSupportSelection.item_code}_${colour.name}`
                                        )
                                      }>
                                      <Field
                                        as={Checkbox}
                                        type="checkbox"
                                        name="checked"
                                        value={colour.name}
                                      />
                                    </WrapItem>
                                    {colour.name}
                                  </Wrap>
                                );
                              })}
                          </Stack>
                        </Box>
                      </Box>
                    </GridItem>
                    <GridItem
                      rowSpan={1}
                      colSpan={2}
                      bg={'#582cff'}
                      color={'white'}
                      p={5}
                      borderRadius={5}
                      height={'fit-content'}>
                      <Heading size="l">Informations</Heading>
                      <Text>
                        Vous pouvez choisir plusieurs couleurs, cela vous permettra d'avoir un
                        aperçu du rendu de votre design sur le vêtement. Vous pourrez choisir
                        finalement la couleur qui vous plait le plus à la fin du processus.
                      </Text>
                    </GridItem>
                  </Grid>
                </ModalBody>
                <ModalFooter>
                  <Button
                    onClick={() => {
                      values.checked = [];
                      onClose();
                    }}
                    bg={'red.400'}>
                    Fermer
                  </Button>
                  <Spacer></Spacer>
                  <Button type="submit" bg={'green.400'}>
                    Valider
                  </Button>
                </ModalFooter>
              </Form>
            </ModalContent>
          </Modal>
        )}
      </Formik>
      {/* Custom support modal */}

      <Box
        mb={{ sm: '24px', md: '50px', xl: '0px' }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center">
        {/* Header */}
        <Card
          direction={{ sm: 'column', md: 'row' }}
          mx="auto"
          maxH="330px"
          w={{ sm: '90%', xl: '100%' }}
          justifyContent={{ sm: 'center', md: 'space-between' }}
          align="center"
          p="24px"
          borderRadius="20px"
          mt="100px">
          <Flex align="center" direction={{ sm: 'column', md: 'row' }}>
            <Flex
              align="center"
              mb={{ sm: '10px', md: '0px' }}
              direction={{ sm: 'column', md: 'row' }}
              w={{ sm: '100%' }}
              textAlign={{ sm: 'center', md: 'start' }}>
              <Avatar me={{ md: '22px' }} src={avatar11} w="80px" h="80px" borderRadius="15px">
                <AvatarBadge
                  cursor="pointer"
                  borderRadius="8px"
                  border="transparent"
                  bg="linear-gradient(138.78deg, rgba(6, 11, 40, 0.94) 17.44%, rgba(10, 14, 35, 0.49) 93.55%, rgba(10, 14, 35, 0.69) 93.55%)"
                  boxSize="26px"
                  backdropFilter="blur(120px)">
                  <Icon h="12px" w="12px" color="#fff" as={FaTshirt} />
                </AvatarBadge>
              </Avatar>
              <Flex direction="column" maxWidth="100%" my={{ sm: '14px' }}>
                <Text
                  fontSize={{ sm: 'lg', lg: 'xl' }}
                  color="#fff"
                  fontWeight="bold"
                  ms={{ sm: '8px', md: '0px' }}>
                  Bienvenue sur l'interface de création de produits
                </Text>
                <Text fontSize={{ sm: 'sm', md: 'md' }} color="gray.400">
                  Faites place à votre imagination
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Card>
      </Box>
      <Box
        mb={{ sm: '24px', md: '50px', xl: '20px' }}
        borderRadius="15px"
        px="0px"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        align="center">
        {/* Header */}
        <Card
          direction={{ sm: 'column', md: 'row' }}
          mx="auto"
          w={{ sm: '90%', xl: '100%' }}
          justifyContent={{ sm: 'center', md: 'space-between' }}
          align="center"
          p="24px"
          borderRadius="20px"
          mt={5}>
          <Flex align="center" direction={{ sm: 'column', md: 'row' }}>
            <Flex
              align="center"
              mb={{ sm: '10px', md: '0px' }}
              direction={{ sm: 'column', md: 'row' }}
              w={{ sm: '100%' }}
              textAlign={{ sm: 'center', md: 'start' }}>
              <Flex direction="column" maxWidth="100%" my={{ sm: '14px' }}>
                <Text
                  fontSize={{ sm: 'lg', lg: 'xl' }}
                  color="#fff"
                  fontWeight="bold"
                  ms={{ sm: '8px', md: '0px' }}>
                  Choissisez le support
                </Text>
                <Text fontSize={{ sm: 'sm', md: 'md' }} color="gray.400">
                  99% des vêtements ne sont pas recyclé. Bienvenue dans le 1%.
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex align="center" direction={{ sm: 'column', md: 'row' }} mt={5}>
            {isSupportChoosed ? (
              <Grid
                gridTemplateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(3, 1fr)'
                }}>
                <Box w="300px">
                  {' '}
                  {/* */}
                  <Box
                    p={5}
                    shadow="md"
                    maxHeight={500}
                    maxWidth={20}
                    borderWidth="1px"
                    minW="56"
                    bgColor="white"
                    mt={2}
                    borderRadius="2xl"
                    cursor="pointer"
                    display="flex"
                    flexDirection="column">
                    <Heading fontSize="xl" mb={3}>
                      {currentSupportSelection.name}
                    </Heading>
                    <Image
                      referrerPolicy="no-referrer"
                      src={currentSupportSelection.colours[0].url}
                      transition="all .3s ease"
                      _hover={{ transform: 'rotate(15deg)' }}
                      objectFit="cover"
                      height="auto"
                      maxWidth="100%"
                    />{' '}
                    <Spacer />
                    <Button
                      bgColor="red"
                      color="white"
                      mt={4}
                      onClick={() => resetChoosenSupport()}>
                      Changer
                    </Button>
                  </Box>
                </Box>

                <Box m={5} color="white" textAlign={'left'}>
                  <Heading fontSize="xl">Détails de votre article</Heading>
                  <Text mt={2}>
                    Couleur{choosedSupportColours.split(',').length > 1 && 's'} choisis :
                  </Text>
                  <Box m={5}>
                    <UnorderedList>
                      {choosedSupportColours.split(',').map((couleur, index) => {
                        return <ListItem key={index}>{couleur}</ListItem>;
                      })}
                    </UnorderedList>
                  </Box>
                </Box>
              </Grid>
            ) : (
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(4, 1fr)',
                  '2xl': 'repeat(4, 1fr)'
                }}
                gap={6}>
                {supportList.data.map((support, index) => {
                  return (
                    <Box
                      p={5}
                      shadow="md"
                      borderWidth="1px"
                      minW="56"
                      bgColor="white"
                      mt={2}
                      borderRadius="2xl"
                      display="flex"
                      flexDirection="column"
                      justifyContent="space-between"
                      height="100%"
                      key={index}>
                      <Heading fontSize="xl" mb={3}>
                        {support.name}
                      </Heading>
                      <Image
                        referrerPolicy="no-referrer"
                        src={staticSupportImages[`${support.item_code}_${support.colours[0].name}`]}
                        transition="all .3s ease"
                        _hover={{ transform: 'rotate(15deg)' }}
                        objectFit="cover"
                        height="auto"
                        maxWidth="100%"
                      />
                      <Spacer />
                      <Button
                        bgColor="#582cff"
                        color="white"
                        mt={4}
                        onClick={() => {
                          setLastColourUrl(null);
                          setChoosedSupportItemCode(support.item_code);
                          openSupportCustomModal(support);
                        }}>
                        Choisir
                      </Button>
                    </Box>
                  );
                })}
              </Grid>
            )}
          </Flex>

          <Flex align="center" direction={{ sm: 'column', md: 'row' }} mt={5}>
            <Flex
              align="center"
              mb={{ sm: '10px', md: '0px' }}
              direction={{ sm: 'column', md: 'row' }}
              w={{ sm: '100%' }}
              textAlign={{ sm: 'center', md: 'start' }}>
              <Flex direction="column" maxWidth="100%" my={{ sm: '14px' }}>
                <Text
                  fontSize={{ sm: 'lg', lg: 'xl' }}
                  color="#fff"
                  fontWeight="bold"
                  ms={{ sm: '8px', md: '0px' }}>
                  Quel design voulez-vous créer ?
                </Text>
                <Text fontSize={{ sm: 'sm', md: 'md' }} color="gray.400">
                  Presque tout est possible graçe à l'intelligence artificielle.
                </Text>
              </Flex>
            </Flex>
          </Flex>

          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(3, 1fr)',
              lg: 'repeat(3, 1fr)'
            }}
            templateRows={{
              base: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              lg: 'repeat(1, 2fr)'
            }}>
            <GridItem rowSpan={1} colSpan={2}>
              {promptInputHandler(isMobile)}
            </GridItem>

            <GridItem rowSpan={1} colSpan={1} mt={[0, 2, 0]}>
              <Button
                disabled={!isSupportChoosed}
                onClick={generateImageSubmit}
                isLoading={isImageGenerating}>
                Envoyer
              </Button>
            </GridItem>
          </Grid>

          <Flex>
            <Text
              fontSize={{ sm: 'lg', lg: 'xl' }}
              color="#fff"
              fontWeight="bold"
              mt={'10'}
              ms={{ sm: '8px', md: '0px' }}>
              Résultat
            </Text>
          </Flex>
          {imageUrl && (
            <>
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(2, 2fr)'
                }}>
                <Box m={5} textAlign={'left'}>
                  <Image
                    src={imageUrl}
                    width={'2xl'}
                    referrerpolicy="no-referrer"
                    borderRadius={'3xl'}></Image>
                </Box>
                <Box direction="column" maxWidth="100%" textAlign={'left'} m={5}>
                  <Text color="#fff">
                    Un élément génant sur votre image, vous pourrez l'éditer pour supprimer ce
                    dernier !{' '}
                    <Badge colorScheme="yellow" mb={10}>
                      Soon...
                    </Badge>
                  </Text>
                  <Text color="black" as="mark" borderRadius={'sm'} p={1}>
                    Une fois le design sélectionné,<b> le fond de l'image sera supprimé</b> avant
                    d'être imprimé sur votre support !
                  </Text>
                </Box>
                <Box maxWidth="90%">
                  <Card m={5} bg={'white'} backdropFilter="blur(120px)" borderRadius="15px">
                    {productUrl ? (
                      <>
                        <Flex textAlign={'left'}>
                          Vous pouvez maintenant avoir un aperçu du rendu sur le produit et le
                          commander !
                        </Flex>
                        <Flex pl={5} pt={4}>
                          <Button
                            _hover={{
                              transform: 'rotate(5deg)',
                              bg: 'purple.400',
                              cursor: 'pointer'
                            }}
                            color={'white'}
                            bg={'purple.400'}
                            maxWidth={'28'}
                            onClick={() => window.open(productUrl, '_blank', 'noreferrer')}>
                            Voir l'article
                          </Button>
                        </Flex>
                      </>
                    ) : (
                      <>
                        <Flex textAlign={'left'}>
                          Le design vous plait vous plait ? <br /> Générer l'article en cliquant sur
                          ce bouton.
                        </Flex>
                        <Flex pl={5} pt={4}>
                          <Button
                            _hover={{
                              transform: 'rotate(5deg)',
                              bg: 'purple.400',
                              cursor: 'pointer'
                            }}
                            color={'white'}
                            bg={'green.400'}
                            maxWidth={'32'}
                            onClick={createProductSubmit}
                            isLoading={isProducGenerating}>
                            Générer l'article
                          </Button>
                        </Flex>
                      </>
                    )}
                  </Card>
                </Box>
              </Grid>
            </>
          )}
        </Card>
      </Box>
    </Flex>
  );
}

export default Profile;
